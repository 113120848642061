import { Dialog, TransitionChild, Transition, DialogPanel } from "@headlessui/react";
import { Fragment, useState } from "react";
import Icon from "./Icon";
import { twMerge } from "tailwind-merge";
import { l } from "./languageUtils";
import { useForm } from "react-hook-form";
import React from "react";
import * as Sentry from "@sentry/astro";
import { object, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const formSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  email: z.string().email({ message: "Invalid email address" }),
  phone: z.string().optional(),
  message: z.string().min(1, { message: "Message is required" }),
});

interface GetInTouchModalProps {
  buttonImage?: string;
  altTag?: string;
  "client:load"?: boolean;
  lang: "CA-fr" | "en";
}

export default function GetInTouchModal({ buttonImage, altTag, lang }: GetInTouchModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: zodResolver(formSchema),
  });

  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const onSubmit = async (data: any) => {
    console.log(data);

    // generate a unique ID for the form submission
    const formSubmissionId = Math.random().toString(36).substring(2, 15);

    // setShowAgreementModal(true);

    try {
      const res = await fetch("https://data-collector.theadpharm.com/capture", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          formSubmissionId,
          lang,
          type: "empaveli_coverage_microsite_get_in_touch_with_sobi",
        }),
      });

      if (!res.ok) {
        throw new Error("Network response was not ok");
      }

      const response = await res.json();

      // push event to GTM
      try {
        (window as any)["dataLayer"] = (window as any)["dataLayer"] || [];
        // prettier-ignore
        (window as any)["dataLayer"].push({
          "event": "form_submission",
          "form_type": "empaveli_coverage_microsite_get_in_touch_with_sobi",
          "form_submission_id": formSubmissionId,
        });
      } catch (error) {
        console.error("Error:", error);

        Sentry.captureException(error);
      }

      setSuccess(true);
    } catch (error) {
      console.error("Error:", error);

      Sentry.captureException(error);
      setFailure(true);
    }

    // setShowAgreementModal(false);

    // closeModal();
    reset();
  };

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    setSuccess(false);
  }

  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className="flex flex-col self-center transition-all opacity-100 transform hover:scale-110 hover:opacity-95 group sm:w-5/12"
      >
        <img src={buttonImage} alt={altTag} className="object-contain cursor-pointer-auto" />
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/60" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <button
                type="button"
                className="absolute z-50 top-0 right-0 bg-blue-headers rounded-bl-lg flex items-center justify-center text-5xl hover:bg-[#5A4A88] hover:text-blue-headers focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#5A4A88] cursor-pointer"
                onClick={closeModal}
              >
                <Icon
                  name="close"
                  className={twMerge(
                    "size-10 sm:size-14 block bg-blue-headers p-2 text-white hover:bg-[#5A4A88] rounded-bl-lg bg-[#5A4A88]",
                  )}
                />
              </button>
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-lg transform overflow-hidden rounded-3xl bg-[#F8F8F8] text-left align-middle shadow-xl transition-all px-4 py-6">
                  {failure ? (
                    <div className="max-w-xs mx-auto text-center">
                      <h1 className="text-2xl font-bold text-gray-700">
                        {/* TODO: verify copilot translations */}
                        {l("Something went wrong", "Quelque chose a mal tourné")}
                      </h1>
                      <p className="pt-2 text-sm text-gray-700">
                        {/* TODO: verify copilot translations */}
                        {l(
                          "Sorry, we were unable to send your message. Please try again, and if the problem persists contact us directly here:",
                          "Désolé, nous n’avons pas pu envoyer votre message. Veuillez réessayer, et si le problème persiste, contactez-nous directement ici :",
                          lang,
                        )}

                        <a
                          href="https://www.sobi.com/canada/en/contact-us"
                          target="_blank"
                          rel="noreferrer"
                          className="inline-block underline"
                        >
                          https://www.sobi.com/canada/en/contact-us
                        </a>
                      </p>
                      <button
                        type="button"
                        className="mt-4 p-2 bg-[#37B2C4] text-white rounded-full hover:bg-[#a8dbe3] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5A4A88] w-full"
                        onClick={closeModal}
                      >
                        {l("Done", "Terminé", lang)}
                      </button>
                    </div>
                  ) : success ? (
                    <div className="max-w-xs mx-auto text-center">
                      <h1 className="text-2xl font-bold text-gray-700">
                        {/* TODO: verify copilot translations */}
                        {l("Thank you for reaching out!", "Merci de nous avoir contactés !", lang)}
                      </h1>
                      <p className="pt-2 text-sm text-gray-700">
                        {/* TODO: verify copilot translations */}
                        {l(
                          "We have received your message and will get back to you as soon as possible.",
                          "Nous avons bien reçu votre message et vous répondrons dans les plus brefs délais.",
                          lang,
                        )}
                      </p>
                      <button
                        type="button"
                        className="mt-4 p-2 bg-[#37B2C4] text-white rounded-full hover:bg-[#a8dbe3] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5A4A88] w-full"
                        onClick={closeModal}
                      >
                        {l("Done", "Terminé", lang)}
                      </button>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="max-w-xs mx-auto text-center">
                        <h1 className="text-2xl font-bold text-gray-700">
                          {l("Get in touch with Sobi", "Prenez contact avec Sobi", lang)}
                        </h1>

                        <p className="pt-2text-sm text-gray-700">
                          {l(
                            "Fill out this form for any queries you may have and we will get back to you as soon as possible",
                            "En cas de questions, remplissez ce formulaire et nous vous répondrons dans les plus brefs délais!",
                            lang,
                          )}
                        </p>
                        <div className="mt-4 mx-auto text-center max-w-xs">
                          <input
                            {...register("name", { required: true })}
                            placeholder={l("Name*", "Nom*", lang)}
                            type="text"
                            id="name"
                            autoComplete="name"
                            className="mt-1 block w-full px-4 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5A4A88] focus:border-blue-headers sm:text-sm shadow-inner"
                          />
                          {errors.name && (
                            <p className="text-xs pl-1 pt-1 text-left text-red-500">
                              {lang === "en" ? errors.name.message?.toString() : "Le nom est requis"}
                            </p>
                          )}
                        </div>
                        <div className="mt-4 mx-auto text-center max-w-xs">
                          <input
                            {...register("email", { required: true })}
                            placeholder={l("Email*", "Courriel*", lang)}
                            type="email"
                            id="email"
                            autoComplete="email"
                            className="mt-1 block w-full px-4 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5A4A88] focus:border-blue-headers sm:text-sm shadow-inner"
                          />
                          {errors.email && (
                            <p className="text-xs pl-1 pt-1 text-left text-red-500">
                              {lang === "en" ? errors.email.message?.toString() : "L'adresse courriel est invalide"}
                            </p>
                          )}
                        </div>
                        <div className="mt-4 mx-auto text-center max-w-xs">
                          <input
                            {...register("phone")}
                            placeholder={l("Phone Number", "Numéro de téléphone", lang)}
                            type="tel"
                            id="phone"
                            autoComplete="tel"
                            className="mt-1 block w-full px-4 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5A4A88] focus:border-blue-headers sm:text-sm shadow-inner"
                          />
                        </div>
                        <div className="mt-4 mx-auto max-w-xs">
                          <label htmlFor="message" className="block text-sm font-medium text-gray-400 text-left">
                            {l("What can we help you with?*", "En quoi pouvons-nous vous aider?*", lang)}
                          </label>
                          <textarea
                            {...register("message", { required: true })}
                            id="message"
                            rows={6}
                            className="mt-1 block w-full px-4 py-2 border rounded-3xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5A4A88] focus:border-blue-headers sm:text-sm shadow-inner"
                            placeholder={l("Message*", "Message*", lang)}
                          />
                          {errors.message && (
                            <p className="text-xs pl-1 pt-1 text-left text-red-500">
                              {lang === "en" ? errors.message.message?.toString() : "Le message est requis"}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="g-recaptcha" data-sitekey="6LcFNOcpAAAAAKOnC2_iU6NJ_GPhKUov1BhWjfbe"></div>

                      <div className="mt-4 mx-auto text-center max-w-xs">
                        <button
                          type="submit"
                          className="p-2 bg-[#37B2C4] text-white rounded-full hover:bg-[#a8dbe3] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5A4A88] w-full"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? l("Submitting...", "Soumission...", lang) : l("Submit", "Soumettre", lang)}
                        </button>

                        {Object.keys(errors).length > 0 && (
                          <div className="pt-4 flex justify-center">
                            <div className="px-1 py-0.5 inline-flex items-center text-center bg-red-600">
                              <Icon as="div" name="emptyExclamation" className="flex-shrink-0 mr-1 text-white" />
                              <p className="text-white/80 text-xs">
                                {l("Please fix the errors above", "Veuillez corriger les erreurs ci-dessus", lang)}
                              </p>
                            </div>
                          </div>
                        )}

                        <p className="text-xs pt-4">
                          {l(
                            "By submitting this form, you consent to have Sobi Canada, Inc. contact you and help answer your queries.",
                            "En soumettant ce formulaire, vous consentez à ce que Sobi Canada, Inc. communique avec vous.",
                            lang,
                          )}
                        </p>
                      </div>
                    </form>
                  )}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
